@import '../../variables.scss';

header {
    --headerHeight: 75px;
    display: flex;
    max-width: 100%;
    margin: 0;
    height: var(--headerHeight);
    width: 100%;
    min-width: 200px;
    position: relative;
    transition: all var(--transitionduration) linear;
    align-items: center;
    justify-content: space-between;

    color: var(--darkcolor);
    .nightMode & {
        color: var(--lightcolor);
    }

    @media (max-width: $compactViewThreshold) {
        --headerHeight: 60px;
    }

    .app-back {
        font-family: 'Poiret One', sans-serif;
        font-size: 40px;
        font-weight: normal;
        padding-right: 20px;
        padding-top: 5px;
    }

    .logo {
        display: flex;
        font-family: 'Poiret One', sans-serif;
        font-size: 40px;
        font-weight: normal;
        align-items: center;
        user-select: none;
        margin-left: 30px;
    }
}

