@font-face {
    font-family: "PT Sans";
    font-style: normal;
    font-weight: 400;
    src: local("PT Sans"), local("PTSans-Regular"),
      url("./assets/fonts/ptsansregular.woff") format("woff");
  }
  @font-face {
    font-family: "PT Sans";
    font-style: normal;
    font-weight: 700;
    src: local("PT Sans Bold"), local("PTSans-Bold"),
      url("./assets/fonts/ptsansbold.woff") format("woff");
  }
  @font-face {
    font-family: "Poiret One";
    font-style: normal;
    font-weight: 400;
    src: local("Poiret One"), local("PoiretOne-Regular"),
      url("./assets/fonts/poiretone.woff") format("woff");
  }
  