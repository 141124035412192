@import '../../variables.scss';

.tag-details .graph {
    width: 100%;
    height: 400px;
    margin-top: 40px;
    overflow: hidden;
    flex-shrink: 0;

    background: var(--lightcolor);
    color: white;
    .nightMode & {
        background: var(--darkcolor);
        color: black;
    }

    p {
        padding: 0 20px;
        font-size: 0.9em;
        color: var(--lightcolor);
    }

    .graph-tooltip {
        padding: 10px;
        display: flex;
    
        color: var(--darkcolor);
        background: var(--lightcolor);
        .nightMode & {
            background: var(--darkcolor);
            color: var(--lightcolor);
        }
    }

    .apexcharts-tooltip {
        pointer-events: all;
    }
}
