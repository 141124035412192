@import '../../variables.scss';

.tag {
    display: flex;
    flex-direction: column;
    justify-content: center;
    float: left;
    cursor: pointer;
    border-radius: inherit;
    position: relative;
    width: 100%;
    height: 160px;
    min-width: 120px;
    user-select: none;
    outline: none;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.5);
    z-index: 1;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    tap-highlight-color: transparent;
    transition: width 0.5s cubic-bezier(0, 0, 0, 0.9), transform 0.5s cubic-bezier(0, 0, 0, 0.9),
        box-shadow 0.2s ease-out;
    color: var(--lightcolor);

    opacity: 0;
    animation: appear .2s ease-out forwards;
    animation-delay: calc(var(--appearDelay) * .1s);
    animation-duration: calc(var(--appearDelay) * 1s);

    background: var(--darkcolor);
    .nightMode & {
        background: black;
    }

    &:last-child {
        margin-bottom: 60px;
    }

    @media (min-width: $compactViewThreshold) {
        height: 200px;
    }

    @media (min-width: 300px) {
        width: 50%;
    }
    @media (min-width: 600px) {
        width: 33%;
    }
    @media (min-width: 790px) {
        width: 25%;
    }
    @media (min-width: 920px) {
        width: 200px;
    }

    &:hover {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.5), 0 0 100px #00000040 inset;
    }

    &:not(.menu-opened):not(.tag-add-btn):active {
        transform: scale(0.94) rotateY(10deg);
        box-shadow: 0 0 0 rgba(0, 0, 0, 0.1);
        z-index: 1;
    }
    
    &.menu-opened:not(.tag-add-btn) {
        transform: rotateY(-180deg) scale(1.09);
    }
    &.menu-opened {
        transform: rotateY(-180deg);
        z-index: 1000;
        box-shadow: 1px 10px 45px 2px rgba(0, 0, 0, 0.4);

        .tag-menu {
            opacity: 1;
            pointer-events: all;
        }

        .graph {
            transform: rotateY(180deg);
        }
    }

    &.tag-add-btn {
        &.menu-opened .tag-menu {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }
    &:not(.tag-add-btn) .tag-menu {
        transform: rotateY(-180deg);
    }

    .tag-menu {
        pointer-events: none;
        opacity: 0;
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: inherit;
        transition: all 0.5s cubic-bezier(0, 0, 0, 0.9), opacity 0s ease-out;

        .tag-menu-item {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50%;
            height: 50%;
            float: left;
            font-weight: bold;
            font-size: 32px;
            transition: box-shadow 0.2s ease-out;

            &:hover {
                box-shadow: 0 0 100px #00000040 inset;
                transition: none;
            }
            &:nth-child(1) {
                background: rgba(0, 0, 0, 0.1);
            }
            &:nth-child(2) {
                background: rgba(0, 0, 0, 0.3);
            }
            &:nth-child(3) {
                background: rgba(0, 0, 0, 0.2);
            }
            &:nth-child(4) {
                background: rgba(0, 0, 0, 0.4);
            }

            .tag-menu-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 40px;
            }
        }
        .tag-menu-delete {
            line-height: 72px;
        }
        .tag-menu-details {
            line-height: 60px;
        }
        .menu-counter {
            opacity: 0.5;
            font-family: 'Poiret One', sans-serif;
        }
    }

    .graph {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        opacity: 0.1;

        //-webkit-transition: opacity .2s cubic-bezier(0.215, 0.61, 0.355, 1);

        svg {
            position: absolute;
            top: 0;
            left: 0;
        }
        path {
            stroke: transparent;
        }
    }

    .value {
        display: block;
        font-size: 80px;
        text-align: center;
        font-family: 'Poiret One', sans-serif;
        line-height: 84px;
        user-select: none;
        user-select: none;
        outline: none;
        text-shadow: 5px 6px 9px #00000081;

        @media (max-width: $compactViewThreshold) {
            font-size: 60px;
        }
    }
    .title {
        display: block;
        font-size: 20px;
        text-align: center;
        line-height: 21px;

        @media (max-width: $compactViewThreshold) {
            font-size: 18px;
        }
    }
}

@keyframes appear {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
