@import "./variables";

:root, body {
    
  --lightcolor: #{$lightcolor};
  --darkcolor: #{$darkcolor};
  --darkhover: #{$darkhover};
  --transitionduration: #{$transitionduration};
  --compactViewThreshold: #{$compactViewThreshold};
}

* {
  box-sizing: border-box;
}
html,
body,
#root {
  overflow: hidden;
}
html,
body,
#root,
.app {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
body {
  font-family: "PT Sans", sans-serif;
  font-size: 18px;
}
a {
  text-decoration: none;
  color: inherit;
}
ul {
  list-style-type: none;
}
h1,
p {
  margin: 0;
  padding: 0;
}

.debug {
  position: absolute;
  display: inline-block;
  padding: 10px;
  bottom: 0;
  right: 0;
  &::before {
    content: "debug ";
    color: #bbb;
  }
}
.wrap {
  max-width: 100%;
  margin: auto;
  position: relative;
}

.d-flex {
  display: flex;
}
.o-hidden {
  overflow: hidden;
}
.shrink-0 {
  flex-shrink: 0;
}



.tooltip[data-tooltip],
.tooltip.tooltip-visible[data-tooltip] {
    position: relative;
}
.tooltip[data-tooltip]::before,
.tooltip.tooltip-visible[data-tooltip]::before {
    content: '';
    position: absolute;
    top: -6px;
    left: 50%;
    transform: translateX(-50%);
    border-width: 4px 6px 0 6px;
    border-style: solid;
    border-color: #000000a0 transparent transparent transparent;
    z-index: 1099;
    opacity: 0;
}

.tooltip[data-tooltip-position='left']::before,
.tooltip.tooltip-visible[data-tooltip-position='left']::before {
    left: 0%;
    top: 50%;
    margin-left: -12px;
    transform: translatey(-50%) rotate(-90deg);
}
.tooltip[data-tooltip-position='top']::before,
.tooltip.tooltip-visible[data-tooltip-position='top']::before {
    left: 50%;
}
.tooltip[data-tooltip-position='bottom']::before,
.tooltip.tooltip-visible[data-tooltip-position='bottom']::before {
    top: 100%;
    margin-top: 8px;
    transform: translateX(-50%) translatey(-100%) rotate(-180deg);
}
.tooltip[data-tooltip-position='right']::before,
.tooltip.tooltip-visible[data-tooltip-position='right']::before {
    left: 100%;
    top: 50%;
    margin-left: 0;
    transform: translatey(-50%) rotate(90deg);
}

.tooltip[data-tooltip]::after,
.tooltip.tooltip-visible[data-tooltip]::after {
    content: attr(data-tooltip);
    font-family: Arial, Helvetica, sans-serif;
    font-weight: normal;
    position: absolute;
    left: 50%;
    top: -6px;
    transform: translateX(-50%) translateY(-100%);
    background: #000000a0;
    // backdrop-filter: blur(4px);
    text-align: center;
    color: #fff;
    padding: 4px 2px;
    font-size: 12px;
    min-width: 80px;
    min-height: 14px;
    pointer-events: none;
    padding: 4px 4px;
    z-index: 1099;
    opacity: 0;
}

.tooltip[data-tooltip-position='left']::after,
.tooltip.tooltip-visible[data-tooltip-position='left']::after {
    left: 0%;
    top: 50%;
    margin-left: -8px;
    transform: translateX(-100%) translateY(-50%);
}
.tooltip[data-tooltip-position='top']::after,
.tooltip.tooltip-visible[data-tooltip-position='top']::after {
    left: 50%;
}
.tooltip[data-tooltip-position='bottom']::after,
.tooltip.tooltip-visible[data-tooltip-position='button']::after {
    top: 100%;
    margin-top: 8px;
    transform: translateX(-50%) translateY(0%);
}
.tooltip[data-tooltip-position='right']::after,
.tooltip.tooltip-visible[data-tooltip-position='right']::after {
    left: 100%;
    top: 50%;
    margin-left: 8px;
    transform: translateX(0%) translateY(-50%);
}

.tooltip[data-tooltip]::after,
.tooltip[data-tooltip]::before {
    opacity: 0;
    transition-delay: 0;
    transition-duration: 0;
}

.tooltip[data-tooltip]:hover::after,
.tooltip[data-tooltip]:hover::before,
.tooltip.tooltip-visible[data-tooltip]::after,
.tooltip.tooltip-visible[data-tooltip]::before {
    opacity: 1;
    transition: opacity .1s ease-in-out;
    transition-delay: .5s;
}

.tooltip[data-tooltip-align='left']::after {
    left: 120%;
    transform: translateX(-35%) translateY(0%);
}
.tooltip[data-tooltip-align='right']::after {
    left: -140%;
    transform: translateX(-30%) translateY(0%);
}
