@import '../../variables.scss';

.tag-add-form-menu {
    transform: rotateY(180deg);
}

.tag-add-form-submit,
.tag-add-form-close {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    cursor: pointer;
    font-size: 20px;
}

.tag-add-form-submit:hover,
.tag-add-form-close:hover {
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    transform: scale(1.1);
}

.tag-add-form-submit {
    right: 0;
}
.tag-add-form-close {
    left: 0;
}
.tag-add-form-field {
    width: 100%;
    min-height: 20px;
    background: none;
    border: none;
    outline: none;
    text-align: center;
    margin: 0;
    padding: 0;
    color: inherit;
}

.tag-add-form-color {
    position: absolute;
    bottom: 0;
    width: 100%;
}


.add-form.opened + .tags {
    margin-top: 60px;
}

