@import '../variables.scss';

.app {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;

    background: var(--lightcolor);
    color: var(--darkcolor);

    &.nightMode {
        background: var(--darkcolor);
        color: var(--lightcolor);
    }

    &::-webkit-scrollbar {
        width: 6px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: rgba(143, 143, 143, 0.8);
        &.nightMode {
            background: var(--darkcolor);
        }
    }
    &::-webkit-scrollbar-thumb:window-inactive {
        background-color: rgba(189, 189, 189, 0.4);
        &.nightMode {
            background: var(--darkcolor);
        }
    }
}

.button {
    background: rgba(0, 0, 0, 0.2);
    border: none;
    color: var(--lightcolor);
    font-size: 31px;
    font-family: 'PT Sans', sans-serif;
    cursor: pointer;
    user-select: none;

    &:hover {
        background: rgba(0, 0, 0, 0.4);
    }
}

.key-incoming {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    transform: translateX(50vh);
    transition: all 0.15s cubic-bezier(0, 0, 0, 1);
}

.page-container {
    position: relative;
    height: 100%;
    width: 100%;
}
.app-main {
    margin-top: 10px;
    padding: 0px 30px;
}

.page {
    position: absolute;
    z-index: 1000;
    width: 100%;
    height: 100%;
    animation: scale-up .2s ease-out;
}

@keyframes scale-up {
    from {
        transform: scale(0);
    }
    to {
        transform: scale(1);
    }
}
