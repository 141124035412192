@import '../../variables.scss';

.tag-details {
    display: flex;
    flex-direction: column;
    text-align: center;
    color: #fff;

    .details-header {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        min-height: 300px;
        padding: 10px 0;
        flex-shrink: 0;
    }

    .details-title {
        display: inline-block;
        min-width: 50px;
        margin: 0 10px;
        font-size: 25px;
        outline: none;

        &:focus {
            border-bottom: 1px dotted black;
        }
        
        &::placeholder {
            color: black;
            opacity: 0.2;
        }
    }

    .details-field {
        width: 100%;
        min-height: 20px;
        background: none;
        border: none;
        outline: none;
        text-align: center;
        margin: 0;
        padding: 0;
    }

    .value {
        font-size: 90px;
        font-family: 'Poiret One', sans-serif;
        margin-top: 20px;
        text-shadow: 5px 6px 9px #00000081;

        &:before {
            color: #aaa;
        }
    }

    .details-actions {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;

        .button {
            color: inherit;
        }
    }
    .button {
        display: flex;
        align-items: center;
        justify-content: center;
        clear: left;
        width: 45px;
        height: 45px;
        margin: 4px 4px 20px;
        text-align: center;
        font-weight: bold;
        font-family: 'Poiret One', sans-serif;
    }

    .details-delete {
        position: absolute;
        right: 50px;
        font-size: 32px;
        font-family: 'Poiret One';
        cursor: pointer;

        @media (max-width: $compactViewThreshold) {
            right: 30px;
            font-size: 26px;
        }
    }
}
