@import '../../variables.scss';

.app-menu {
    max-height: 0;
    transition: max-height 0.15s ease-out;
    overflow: hidden;
    flex: 0 0 auto;

    background: var(--darkcolor);
    .nightMode & {
        background: black;
    }

    &.open {
        max-height: 230px;
    }

    .menulist {
        margin: 0;
        padding: 0;
    }

    .menuitem {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 30px;
        height: 50px;
        color: var(--lightcolor);
        font-size: 20px;
        font-family: 'PT Sans', sans-serif;
        cursor: pointer;

        -webkit-transition: background-color var(--transitionduration) linear;
        -moz-transition: background-color var(--transitionduration) linear;
        transition: background-color var(--transitionduration) linear;

        &.disabled {
            opacity: 0.5;
        }

        &:hover:not(.disabled) {
            background: var(--darkhover);
        }
    }
}

.app-menu-button {
    display: flex;
    width: 75px;
    height: 100%;
    cursor: pointer;
}


.menuicon {
    position: relative;
    margin-left: auto;
    width: 27px;
    height: 3px;
    top: 60%;
    right: 30%;
    line-height: 24px;
    cursor: pointer;
    transition: right, top var(--transitionduration) linear;
    user-select: none;

    background: var(--darkcolor);
    .nightMode & {
        background: var(--lightcolor);
    }

    &::after,
    &::before {
        content: ' ';
        display: block;
        width: 27px;
        height: 3px;
        margin-top: 19px;
        background: inherit;
    }
    &::after {
        margin-top: -19px;
    }
    &::before {
        margin-top: -16px;
    }

    &.open {
        box-shadow: 0 -17px 0 51px var(--darkcolor);
        background: var(--lightcolor);

        @media (max-width: $compactViewThreshold) {
            box-shadow: 0 -13px 0 33px var(--darkcolor);
        }

        &::after,
        &::before {
            background: var(--lightcolor);
        }
    }
}
